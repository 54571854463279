import React from 'react';
import { loadStripe } from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import PaymentRequestButton from "../components/CheckOut/PaymentRequestButton"
import PaymentCardForm from "../components/CheckOut/PaymentCardForm"
import { v4 } from 'uuid';

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// loadStripe is initialized with your real test publishable API key.
const promise = loadStripe("pk_test_okk0TZerRWR9ZCyiLFGJ1OYK002vAhrkeS");

export default function App() {
    return (
        <div>
            <Elements stripe={promise}>
                <PaymentCardForm uuid={v4()}/>
            </Elements>
        </div>
    );
}